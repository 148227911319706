@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --background-color: #fafcfd;
  --text-color: #383535;
  --primary-color: #142b41;
  --shadow-color: #00000040;
  --hover-color: #f2f2f2;
  --placeholder-color: #9d9d9d;
}

/* general styles */

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
label {
  font-family: "Poppins", sans-serif;
}

::placeholder,
span {
  font-family: "Poppins", sans-serif;
  color: var(--placeholder-color) !important;
  font-size: 14px !important;
}

li label {
  color: var(--placeholder-color) !important;
}

.selected-black {
  color: #142b41 !important;
}

.main-container {
  display: flex;
  background-color: var(--background-color);
  justify-content: space-between;
  position: relative;
}

.main-container svg {
  display: block;
  height: 100%;
  width: 40%;
}

/* all screen styles */

.set-default-form,
.fulfillment-policy-form,
.payment-policy-form,
.return-policy-form,
.location-policy-form,
.business-policy-form {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
}

.select-store-form-left,
.set-default-form-left {
  width: 50%;
}

.fulfillment-policy-form-left,
.payment-policy-form-left,
.return-policy-form-left,
.location-policy-form-left,
.business-policy-form-left {
  width: 50%;
}

.set-default-form-left h1,
.fulfillment-policy-form-left h1,
.payment-policy-form-left h1,
.return-policy-form-left h1,
.location-policy-form-left h1,
.business-policy-form-left h1 {
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3rem;
}

.select-store-form-left form label,
.set-default-form-left form label,
.fulfillment-policy-form-left form label,
.payment-policy-form-left form label,
.return-policy-form-left form label,
.location-policy-form-left form label,
.business-policy-form-left form label {
  display: block;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1rem;
}

/* select store and set defaults */

.select-store-form {
  width: 54%;
  padding: 4rem;
}

.set-default-form {
  width: 50%;
}

.select-store-form p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: var(--text-color);
  text-align: left;
}

/* create business policy styles */

.business-policy-form-left a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  border-bottom: 0.5px solid var(--primary-color);
}

.business-policy-form-left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  color: rgba(56, 53, 53, 0.8);
  text-align: justify;
  margin-bottom: 2rem;
}

/* custom dropdown styles */

.custom-select {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 5px 5px 0px var(--shadow-color);
  border-radius: 10px;
  margin-bottom: 3rem;
  box-sizing: border-box;
  padding: 1rem;
}

.select-button {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-value {
  text-align: left;
}

.arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
  transition: transform ease-in-out 0.3s;
}

.select-dropdown {
  position: absolute;
  list-style: none;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 10px;
  padding: 10px;
  margin-top: 1.5rem;
  overflow-y: auto;
  box-sizing: border-box;
  left: 0;
  top: calc(100% + 10px); /* Adjust top position to prevent overlap */
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  z-index: 1000; /* Ensure dropdown is on top of other elements */
}

.custom-select.active .arrow {
  transform: rotate(180deg);
}

.custom-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.select-dropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown li label {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  margin-bottom: unset !important;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover,
.select-dropdown input:checked ~ label {
  background-color: var(--hover-color);
}

.select-dropdown input:focus ~ label {
  background-color: #dfdfdf;
}

.select-dropdown input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* custom checkbox styles */

.checkbox-inline {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.checkbox-inline label {
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 35px !important;
  text-align: center !important;
  color: #9d9d9d !important;
  margin-bottom: unset !important;
}

.checkbox-inline input {
  width: 60px;
  height: 30px;
}

/* next button styles */

.next-btn {
  width: 60%;
  text-align: center;
}

/* For desktop screens with a minimum width of 1024px */
@media only screen and (min-width: 1024px) {
  .spinner3 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 56.5%;
    margin-top: -12px;
    transform: translate(-50%, -50%);
  }
  .select-store-form,
  .set-default-form,
  .fulfillment-policy-form,
  .payment-policy-form,
  .return-policy-form,
  .location-policy-form,
  .business-policy-form {
    padding: 3.5rem;
  }

  .set-default-form-left h1,
  .fulfillment-policy-form-left h1,
  .payment-policy-form-left h1,
  .return-policy-form-left h1,
  .location-policy-form-left h1,
  .business-policy-form-left h1 {
    font-size: 32px;
    margin-bottom: 3rem;
  }

  .select-store-form-left form label,
  .set-default-form-left form label,
  .fulfillment-policy-form-left form label,
  .payment-policy-form-left form label,
  .return-policy-form-left form label,
  .location-policy-form-left form label,
  .business-policy-form-left form label {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .select-store-form p {
    font-size: 32px;
  }

  .select-store-checkbox {
    width: 150px;
    height: 150px;
  }

  .business-policy-form-left a {
    font-size: 16px;
  }

  .business-policy-form-left p {
    font-size: 20px;
    margin-bottom: 2rem;
  }

  .custom-select {
    font-size: 16px;
  }
}

/* For tablets */
@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .select-dropdown {
    top: calc(100% + 5px); /* Adjust top position for tablets */
  }
  .select-store-form,
  .set-default-form,
  .fulfillment-policy-form,
  .payment-policy-form,
  .return-policy-form,
  .location-policy-form,
  .business-policy-form {
    padding: 3rem;
  }

  .fulfillment-policy-form-left,
  .payment-policy-form-left,
  .return-policy-form-left,
  .location-policy-form-left,
  .business-policy-form-left {
    width: 50%;
  }

  .set-default-form-left h1,
  .fulfillment-policy-form-left h1,
  .payment-policy-form-left h1,
  .return-policy-form-left h1,
  .location-policy-form-left h1,
  .business-policy-form-left h1 {
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .select-store-form-left form label,
  .set-default-form-left form label,
  .fulfillment-policy-form-left form label,
  .payment-policy-form-left form label,
  .return-policy-form-left form label,
  .location-policy-form-left form label,
  .business-policy-form-left form label {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .select-store-form p {
    font-size: 26px;
  }

  .select-store-checkbox {
    width: 120px;
    height: 120px;
  }

  .business-policy-form-left a {
    font-size: 16px;
  }

  .business-policy-form-left p {
    font-size: 18px;
    margin-bottom: 2rem;
  }

}
form.select-store-form-left,
button.select-store-form-left {
  font-family: "Poppins";
  font-style: normal; 
  background : #FFFFFF;
  border-radius : 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    width: 185px;
    height: 200px;
    padding: 1rem;
    box-shadow: 0px 5px 5px 0px #00000040;
    margin-top: 3.5rem;
}


  .custom-select {
    font-size: 16px;
  }

/* Mobile */
@media only screen and (max-width: 768px) {
  .spinner3 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 56.5%;
    margin-top: -12px;
    transform: translate(-50%, -50%);
  }
  .main-container svg {
    display: none;
  }
  .select-dropdown {
    top: calc(100% + 2px); /* Adjust top position for mobile */
  }
  .main-container{
    margin-left: 26px;
  }

  .select-store-form,
  .set-default-form,
  .fulfillment-policy-form,
  .payment-policy-form,
  .return-policy-form,
  .location-policy-form,
  .business-policy-form {
    padding: 1.5rem;
  }

  .fulfillment-policy-form-left,
  .payment-policy-form-left,
  .return-policy-form-left,
  .location-policy-form-left,
  .business-policy-form-left {
    width: 100%;
    margin-left: 26px;
  }

  .select-store-form,
  .set-default-form {
    width: 100%;
  }

  .set-default-form-left h1,
  .fulfillment-policy-form-left h1,
  .payment-policy-form-left h1,
  .return-policy-form-left h1,
  .location-policy-form-left h1,
  .business-policy-form-left h1 {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .select-store-form-left form label,
  .set-default-form-left form label,
  .fulfillment-policy-form-left form label,
  .payment-policy-form-left form label,
  .return-policy-form-left form label,
  .location-policy-form-left form label,
  .business-policy-form-left form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .select-store-form p {
    font-size: 20px;
  }

  .select-store-checkbox {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .business-policy-form-left a {
    font-size: 14px;
  }

  .business-policy-form-left p {
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  .custom-select {
    font-size: 14px;
  }

  .checkbox-inline label {
    font-size: 12px !important;
  }

  .next-btn {
    margin: auto;
    display: block;
  }
}
.diagonal-arrow-button {
  display: inline-block;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border: 2px transparent; 
  border-radius: 10px; 
  text-decoration: none; /* Remove default underline */
  color: #383535; /* Arrow color */
  font-size: 20px; /* Adjust arrow size as needed */
  font-weight: 900;
  line-height: 50px; /* Center arrow vertically */
  text-align: center; /* Center arrow horizontally */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);  
}

.diagonal-arrow-button:hover {
  background-color: #f2f2f2; /* Background color on hover */
}

/* styles.css */
/* styles.css */
.spinner-container {
  position: relative;
  height: 100vh; /* Assuming full height */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #142b41;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 150px; /* Adjust this value as needed */
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading{
  top: 230px; /* Adjust this value as needed */
  left: 51.5%;
  position: absolute;
  transform: translate(-50%, -50%);
  
}

.spinner2-container {
  position: relative;
  height: 100vh; /* Assuming full height */
}
.spinner3-container {
  position: relative;
  height: 100vh; /* Assuming full height */
}

.spinner2 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 13.5%;
  margin-top: -12px;
  transform: translate(-50%, -50%);
}
.spinner3 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 20.5%;
  margin-top: -12px;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.edit-inventory-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#tog-sidebar{
  margin-left: 80px;
}

.edit-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust the gap as needed */
}
.backButton{
  padding: 10px;
  height: 60px;
  width: 140px;
  border: none;
  font-size: 15px;
  color: #9D9D9D;
  background-color: #fcf9f9;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  height: 60px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.form-column {
  display: flex;
  flex-direction: column;
}
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 800px; /* Adjust the width as needed */
  margin: 20px;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

/* Apply these styles to your select dropdown */
label {
  display: block;
  margin-bottom: 0.5rem;
}

select {
  width: 100%;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Styling for the arrow icon */
select::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3rem 0.3rem 0;
  border-color: #495057 transparent transparent;
  pointer-events: none;
}

/* Hover and focus styles */
select:hover {
  border-color: #6c757d;
}

select:focus {
  border-color: #6c757d;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

/* Disabled state */
select:disabled {
  background-color: #e9ecef;
  opacity: 0.65;
}
input{
  text-align: center;
  height: 55px;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}
textarea{
  text-align: center;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}
.submitButton{
  padding: 15px;
  font-size: 16px;
  width: 10%;
  border-radius: 8px;
  outline: none;
  background: #fff;
  border: 1px solid #e9e7e7;
  border-top: none;
  box-sizing: border-box;
  color: #9D9D9D;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}



button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.sidebar {
  position: fixed; /* Change to fixed positioning */
  overflow: hidden;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background: #142b41;
  transition: width 0.4s;
  z-index: 1000; /* Set a high z-index */
}

.sidebar.open {
  width: 260px;
}
.sidebar.open +  #tog-sidebar{
  margin-left: 260px;
  transition: ease 300ms;
}

#tog-sidebar{
  margin-left: 26px;
}



/* Rest of your CSS remains the same */


.sidebar-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 260px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  height: 72px;
  background: rgb(0 0 0 / 15%);
}

.sidebar-burger {
  width: 60px;
  height: 72px;
  display: grid;
  place-items: center;
  color: #f9f9f9;
}

.sidebar-logo {
  height: 28px;
}

.sidebar-menu {
  display: grid;
  padding: 10px;
}

.sidebar-button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  font-family: "Poppins";
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 10px;
  border-radius: 8px;
  color: #f9f9f9;
  opacity: 0.8;
}

.sidebar-button:hover {
  background: rgb(0 0 0 / 30%);
  opacity: 1;
  transition: ease 300ms;
}

.sidebar-button:hover > span {
  transition: ease 300ms;
  opacity: 1;
}

.sidebar-logo,
.sidebar-button p {
  opacity: 0;
  transition: 0.3s;
}

.sidebar.open :is(.sidebar-button p, .sidebar-logo) {
  opacity: 1;
}

.sidebar-button > img {
  width: 24px;
  height: 24px;
}

.sidebar-button > span {
  opacity: 0.5;
}
.Heading{
  color: white;
  font-family: poppins;
  font-weight: 700;
}
/* mobile */
@media only screen and (max-width: 768px) {
  .sidebar.open +  #tog-sidebar{
    margin-left: 26px;
    transition: ease 300ms;
  }
}
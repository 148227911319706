p > span[data-id]:hover {
  background-color: "#e0e0e0";
}

.aspect {
  margin: 30px;
  width: 400px;
}

.exactValues {
}

.withValues i {
}

.noValue {
}

/* .aspect.withValues{
  flex:1
}
.aspect.noValue{
  flex:1
} */

.aspect.exactValues.true input,
.aspect.exactValues.false input,
.aspect.exactValue.true select,
.aspect.exactValue.false select,
.aspect.noValue.true input,
.aspect.noValue.false input,
.aspect.noValue.true select,
.aspect.noValue.false select,
.aspect.withValues.true input,
.aspect.withValues.false input,
.aspect.withValues.false select .aspect.withValues.true select {
  height: 55px;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}

/* productListing.css */

/* choose categories start */
.listing-wrapper {
  min-height: 100%;
}

.listing-card {
  padding-right: 0;
  padding-top: 0;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  overflow: hidden;
}

.listing-card-left {
  padding: 2rem;
  padding-left: 3rem;
  margin-top: 3rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: 2rem;
}

.listing-card-left p {
  font-size: 20px;
  font-family: poppins;
}

.listing-input-box {
  width: 55%;
  padding: 1rem;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px #00000040;
  background-color: #fafcfd;
}

.button-submit {
  padding: 10px;
  width: 350px;
  background-color: #142b41;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* choose categories end */

.tree-sample p {
  display: flex;
  margin-bottom: 30px;
  word-spacing: 3px;
  color: #868484;
}

.tree-sample p span {
  color: #868484;
}

.tree-sample p span:last-child {
  color: #142b41;
  cursor: pointer;
  text-decoration: underline;
}
.basic-details {
  padding: 30px;
  margin: 30px;
  margin-top: 50px;
}

.basics .input,
.offerDetails .input,
.productDetails .input {
  margin: 30px;
  width: 400px;
  padding-bottom: 20px;
}


.offerDetails .input{
  margin: 0;
}
.innerSection.offerDetails .input label,
.innerSection.basics .input label {
  font-size: 17px;
  color: #383535;
}

.innerSection.offerDetails .input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
}

.input-select {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.innerSection.basics .input textarea,
.innerSection.offerDetails .input textarea {
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}
.red-color{
  color: #d00000 !important;
}
.innerSection.basics .input input,
.innerSection.offerDetails .input input {
  height: 55px;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}

.innerSection.basics .input select,
.innerSection.productDetails .input select,
.innerSection.offerDetails .input select {
  display: block;
  padding: 12px 16px;
  height: 55px;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  padding-left: 20px;
}
.input-select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #131315;
  /*#f1ecec:;
  */
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transform: translateY(-50%);
}

.basic-details form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.basics,
.productDetails,
.offerDetails {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: start;
}

.basics .basic-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.innerSection.offerDetails .offerDetails-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.basics p,
.innerSection.offerDetails p,
.innerSection.productDetails p {
  font-size: 32px;
  margin-top: 20px;
}

.basics .basic-container .input label,
.productDetails label,
.offerDetails .offerDetails-container .input label {
  margin-bottom: 15px;
  color: rgb(69, 68, 68);
  font-size: 18px;
  font-weight: normal;
}

.images label {
  display: block;
  margin-bottom: 15px;
}


.flex-container {
  /* margin-top: 30px; */
  display: flex;
  flex-wrap: wrap;
}
.image-preview {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.preview-images {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.category-card {
  padding: 8px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-card:hover {
  background-color: #e0e0e0;
}

.category-card.selected {
  background-color: #d00000;
  color: #fff;
}

.root-node {
  font-weight: bold;
}

.innerSection.productDetails .productDetails-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Adjust the gap between items */
}

/* Style for each input container */
.aspect {
  display: flex;
  flex-direction: column;
}

/* Label styles */
.aspect label {
  margin-bottom: 5px;
}

/* Input styles */
.aspect input[type="text"],
.aspect input[type="email"],
.aspect input[type="number"],
.aspect input[type="date"],
.aspect select {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

/* Adjust width of select to fill the container */
.aspect select {
  width: 100%;
}

.aspect .input-select select {
  display: block;
  padding: 12px 16px;
  height: 55px;
  margin-top: 5px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 17px;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  padding-left: 20px;
}
.input-select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #131315;
  /*#f1ecec:;
  */
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transform: translateY(-50%);
}

@media screen and (max-width: 1280px) {
  .listing-input-box {
    width: 100%;
  }

  .listing-card div svg {
    display: none;
  }

 .flex-container{
  margin-top: 0;
 }
  .basic-details{
    margin: 10px;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .listing-input-box {
    width: 340px;
  }

  .button-submit {
    width: 280px;
  }
  .listing-card div svg {
    display: none;
  }

  /* Categories Styles */
  .tree-sample p {
    display: block;
    text-align: start;
    word-spacing: normal;
  }

  /* Basic Details Form */
  .basic-details {
    padding: 10px;
    margin: 5px;
    margin-top: 30px;
    align-content: center;
  }

  .basics .basic-container {
    flex-direction: column-reverse;
  }
  .innerSection.productDetails .productDetails-container,
  .innerSection.offerDetails .offerDetails-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .flex-container {
    margin-left: 30px;
  }
}

@media screen and (max-width: 500px) {
  /* Basic Details Form */
  .basic-details {
    padding: 10px;
    margin: 5px;
    margin-top: 30px;
    align-content: center;
  }

  .basic-details form{
    align-items: center;
  }

  
  .basics .input,
  .offerDetails .input,
  .productDetails .input {
    margin: 30px;
    width: 320px;
    padding-bottom: 20px;
  }

  .aspect.exactValue.true,
  .aspect.exactValue.false,
  .aspect.noValue.true,
  .aspect.noValue.false,
  .aspect.withValues.true,
  .aspect.withValues.false,
  .aspect input[type="text"],
  .aspect input[type="email"],
  .aspect input[type="number"],
  .aspect input[type="date"],
  .aspect select {
    width: 320px;
  }
}

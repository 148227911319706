.login-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: #142b41;
}

.login-form {
  flex: 1;
  background-color: #142b41;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form h3 {
  color: white;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

.login-form h3 span {
  font-family: poppins;
  font-weight: 700;
}

.login-form form {
  padding: 16px 40px;
  width: 500px;
}

.login-form form div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #E5E5E5
  ;
}

.login-form form input {
  margin-bottom: 4px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #142b41;
  box-sizing: border-box;
  color: #fff;
}

.img-container {
  flex: 1;
  height: 100%;
  /* height: 100vh; */
}
.login-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-register-link{
  color: #fff;
}
.register{
  color: #ffa500;
}

/* .image-login-module {
  width: 50%;
}

.image-login-module img {
  height: 100vh;
  width: 100%;
}

.login-module {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
} */

@media screen and (max-width: 1280px) {
  .login-form {
    height: 100vh;
  }

  .login-form form {
    width: 400px;
  }

  .img-container {
    object-fit: fill;
  }
}

/* Mobile screen */
@media screen and (max-width: 768px) {
  .login-form {
    height: 100vh;
  }

  .login-form form {
    width: 400px;
  }
  .img-container {
    display: none;
  }
}

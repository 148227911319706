body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins",
  "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-style: normal; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1rem;
  width: fit-content !important;
  height: fit-content;
}

/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

.container {
  margin: 2rem 1rem;
}

.label-text {
  margin-bottom: 5px;
}

.input-box {
  border-radius: 3px;
  color: #898989;
  font-size: 14px;
  line-height: 18px;
  padding: 5px;
  border: solid 1px;
}

.button-submit {
  background-color: #d00000;
  border: none;
  color: white;
  border-radius: 0.25rem;
  padding: 0.5rem 1.75rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}

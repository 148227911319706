.inventory-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
}

.inventrory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
}

.inventrory-header a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #142b41;
}

h2 {
  text-align: start;
  font-weight: normal;
}

.store-img {
  height: 70px;
  width: 100px;
  object-fit: cover;
}

.search-create-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #070606;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.search-icon {
  margin-right: 5px; /* Adjust the margin as needed */
}

.search-icon i {
  color: #bcbcbc; /* Adjust the color as needed */
}

.search-bar input {
  padding: 8px;
  margin-right: 10px;
  width: 300px; /* Adjust as needed */
}

.create-product {
  text-align: left;
  margin-bottom: 20px;
}

.create-product button {
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  background: #fff;
  border: 1px solid #e9e7e7;
  border-top: none;
  box-sizing: border-box;
  color: #9D9D9D;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

.search-bar input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 0px;
  box-shadow: none;
}

.search-bar button {
  padding: 8px 12px;
  margin-left: 5px; /* Adjust the margin as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff; /* Adjust the color as needed */
  color: #fff; /* Adjust the color as needed */
}

.inventory-table {
  max-width: 100%;
  overflow-x: auto;
}

.inventory-table table {
  width: 100%;
  border: none;
  padding: 30px;
  table-layout: auto;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.table {
  border-collapse: collapse; /* Collapse the borders between cells */
  border-spacing: 0; /* Remove any spacing between cells */
}

.table-header {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.table-rows {
  padding: 0;
  margin: 0;
  background-color: #0c74891a;
}

.table-header {
  min-width: 200px;
  padding: 30px;
}

.table thead tr th:first-child {
  border-top-left-radius: 20px;

}

.table thead tr th:last-child {
  border-top-right-radius: 20px;
}

.table-data-cell:first-child{
  color:#9D9D9D
}

.table-data-cell {
  padding: 20px;
  border-bottom: 1px solid #00000033;
}

.table-data-cell div {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.table-action-btn {
  padding: 10px;
  height: 60px;
  width: 140px;
  border: none;
  font-size: 15px;
  color:#9D9D9D;
  background-color: #fcf9f9;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  height: 60px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.table-action-btn:hover,
.create-product button:hover {
  color:#333;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 15px -3px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 15px -3px;

  color: #333;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #e5e5e5;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-content {
  margin-bottom: 20px;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"); /* Import Font Awesome library */

@media screen and (max-width: 768px) {
  .inventrory-header,.search-create-container {
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
  }

  .create-product button{
    width: 300px;
  }
  .search-create-container {
    gap:20px}
  
}


@media screen and (max-width: 550px) {
  .inventory-container {
    
    padding: 10px;
  }
  
  .search-bar input {
    padding: 8px;
    margin-right: 10px;
    width: 270px; /* Adjust as needed */
  }
}
.basic-product-wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: #383535;
}

.basics-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.basics-product .leave p {
  color: #142b41;
  text-decoration: underline;
  font-size: 18px;
}

.basics-products-form {
  /* height: 80vh; */
  margin-top: 30px;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basics-products-form p {
  font-size: 32px;
  text-transform: capitalize;
  margin-right: 30%;
  margin-bottom: 20px;
}

.basics-products-form form {
  margin: 0 20px;
  width: 350px;
  padding: 30px;
  text-align: start;
}

.basics-products-form form div {
}

.basics-products-form form div input {
  margin-bottom: 4px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border: 1px solid #e5e5e5;
  border-top: none;
  box-sizing: border-box;
  color: #070606;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.basics-products-form form div .img-uploader {
  height: 200px;
  width: 200px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* .preview {
  display: flex;
  height:300px;
  width: 350px;
  overflow: scroll;
} */

.preview-image {
  height: 50px;
  width: 50px;
}

.innerSection {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
}

.image-uploader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.image-actions span {
  display: flex;
}

.image-actions svg {
  height: 40px;
  width: 30px;
  cursor: pointer;
}

.images {
  margin-top: 20px;
}

.images p {
  font-size: 16px;
}

.dropzone {
  margin-top: 20px;
  height: 130px;
  width: 150px;
  padding: 30px 44px;
  /* padding: 15px; */
  border: 2px solid #cccccc;
  border-radius: 10px;
  outline: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #3c3c3c;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  cursor: pointer;
}

.dropzone span svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.preview-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: none;
}

.delete-img {
  height: 24px;
  width: 25px;
}

.image-container:hover .delete-icon {
  display: block;
}

#btnUpload,
#btnClear {
  margin-top: 20px;
  padding: 2px 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#btnClear {
  background-color: rgb(240, 238, 238);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 3px 3px;
}
#btnUpload:hover {
  background-color: #0056b3;
}

p {
  margin: 0;
}

.img-basics div span {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-basics div:first-child {
  position: absolute;
  cursor: pointer;
  top: 10%;
  left: 90%;
}

.img-basics div:last-child {
  position: absolute;
  bottom: 10%;
  left: 86%;
}

.img-basics div:first-child button {
  border: none;
  font-size: 18px;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.img-basics div:last-child button {
  height: 50px;
  cursor: pointer;
  width: 150px;
  background: #142b41;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 10px;
}

@media screen and (max-width: 1280px) {
  .basics-products-form form p:first-child{

    /* margin-right: 34%; */
    font-size: 28px;
  }

  .basics-products-form form .images p{
    font-size: 17px;
    text-align: left;
    margin-right: 40%;
  }
  .img-basics div {
    flex-direction: row;
  }

  .img-basics div:last-child {
    position: absolute;
    bottom: 20%;
    left: 80%;
  }

  .basics-product {
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .basic-product-wrapper {
    flex-direction: column;
  }
  .basics-product span {
    margin-right: 30%;
  }

  .basics-products-form {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .basics-products-form p {
    margin-right: 0;
    padding-left: 20px;
  }

  .basics-products-form form {
    padding: 30px 5px;
  }

  

  .img-basics{
    flex-direction: row;
  }

  .img-basics div span {
    display: none;
  }

  .img-basics div:first-child {
    position: absolute;
    top: 10%;
    left: 74%;
  }

  .img-basics div:last-child {
    position: absolute;
    bottom: 20%;
    left: 70%;
  }

  .img-basics div:last-child button {
    height: 40px;
    width: 140px;
    background: #142b41;
    border: none;
    color: #fff;
    font-size: 16px;
  }
}


@media screen and (max-width: 500px) {
  .img-basics div:first-child {
    position: absolute;
    top: 5%;
    left: 80%;
  }

  .img-basics div:last-child {
    position: absolute;
    bottom: 5%;
    left: 70%;
  }
  /* .preview{
    height: 200px;
    width: 300px;
    overflow: scroll;
  } */

}
.product-list-card-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.card-text p:first-child {
  text-align: center;
  font-size: 32px;
}

.card-text p:last-child {
  font-size: 20px;
}

/* Position the SVG */
.card-text > span {
  position: absolute;
  right: 0;
  top: 0;
}

.card-text svg {
  width: 100%;
  height: auto;
  max-width: 200px;
}

.product-list-card-wrapper > div:last-child {
  position: relative;
}

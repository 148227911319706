.list-categories-wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-categories-wrapper h1 {
  margin: 4rem 0;
  font-size: 32px;
  font-weight: normal;
}

.list-categories-wrapper button{
    display: flex;
    align-items: flex-start;
}

.tree-sample {
  color: #868484;
  margin-bottom: 30px;
}
